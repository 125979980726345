/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
'use client';
import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import sty from './styles/Auth.module.css';
import IconCloseModal from 'components/Icons/IconClose';

// eslint-disable-next-line
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

interface Props {
  onClose: (path: string) => void;
  emailRef?: string;
  username?: string;
  signInHandler: MouseEventHandler<HTMLAnchorElement>;
}

const ConfirmUser: FC<Props> = (props) => {
  const [email] = useState(props.emailRef);
  const [showLoginlink, setShowloginlink] = useState<boolean>(false);

  const [error, setError] = useState<string | undefined>(undefined);

  const codeRef = useRef<HTMLInputElement>(null);

  const handleConfirmation = async (event: React.MouseEvent) => {
    event.preventDefault();
    const userData = {
      Username: props.username,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(
      codeRef?.current?.value,
      true,
      function (err: Error, result: string) {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          return handleError(err);
        }
        setError(undefined);
        if (result == 'SUCCESS') setShowloginlink(true);
      }
    );
  };

  const handleResendCode = async (event: React.MouseEvent) => {
    event.preventDefault();

    const userData = {
      Username: props.username,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.resendConfirmationCode(function (err: Error, result: string) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        return handleError(err);
      }
      setError(undefined);
      console.log('call result: ' + result);
    });
  };

  const handleError = (err: Error) => {
    setError(err?.message ?? err.toString().replace(/^\w*?Exception: /i, ''));
  };

  return (
    <div className={`${sty.modal} grid grid-2col has-basic-links`}>
      <div className={sty.modalContainerLeft_confirmUser}>
        <div className={sty.modalOverlay}>
          <div className={sty.modalInnerWrapper}>
            <img
              className={sty.modalOverlayImageOne}
              src={'/img/column-of-colored-triangles3.svg'}
              alt="" /* decorative */
            />
            <div className={`${sty.modalOverlayText} hdg-1`}>
              <div>Welcome back </div>
              <div>
                to news <div className={sty.modalForYou}>for you</div>
              </div>
            </div>
            <div className={sty.modalOverlayImageTwoOuter}>
              <img
                className={sty.modalOverlayImageTwo}
                src={'/img/column-of-colored-triangles4.svg'}
                alt="" /* decorative */
              />
            </div>
          </div>
        </div>
      </div>
      <div className={sty.modalContainerRight}>
        <div className={`${sty.topContainer}`}>
          <div className="hdg-2">
            {showLoginlink ? <>Welcome to MPR News</> : <>Email Verification</>}
          </div>
          {showLoginlink ? (
            <button
              onClick={() => props.onClose('new-user')}
              className={sty.closeButton}
            >
              <IconCloseModal color="var(--color-mpr-charcoal)" />
            </button>
          ) : (
            <></>
          )}
        </div>
        {!showLoginlink && (
          <p>A verification code has been emailed to {email ?? ''}</p>
        )}
        {!showLoginlink && (
          <form>
            <label htmlFor="code" className={`${sty.lbl} ${sty.block} type-sm`}>
              Verification code{' '}
            </label>

            <input
              className={`${sty.inpt} ${sty.largeInput} input-thin`}
              ref={codeRef}
              type="text"
              name="code"
              maxLength={6}
            />
            <div className={`${sty.container} ${sty.containerInner}`}>
              <button
                onClick={handleConfirmation}
                className="btn btn-primary btn-full-width btn-slim"
              >
                Confirm your account
              </button>
            </div>
          </form>
        )}
        {!showLoginlink && (
          <div className={`${sty.supplementalTextBottom} type-sm`}>
            Still awaiting the code?
            <div>
              <div>
                <button onClick={handleResendCode} className="btn-link link">
                  Send another
                </button>
              </div>
            </div>
          </div>
        )}
        {error && (
          <p style={{ color: `var(--color-mpr-red-dark)`, marginTop: '1rem' }}>
            {error}
          </p>
        )}
        {showLoginlink && (
          <>
            {' '}
            <div className={sty.centerContent}>
              <div>
                <img
                  style={{ width: '3rem', height: '3rem', marginTop: '2rem' }}
                  src="/img/icon-verify-check.svg"
                  alt="verify check mark"
                />

                <div
                  style={{
                    color: 'var(--color-mpr-green)',
                  }}
                >
                  Success!
                </div>
              </div>
            </div>
            <p style={{ marginBottom: '10rem' }}>
              You are ready to sign in and get started.
            </p>
          </>
        )}
        {showLoginlink && (
          <div>
            <a
              onClick={props.signInHandler}
              className="btn btn-primary btn-full-width btn-slim"
              style={{ color: 'var(--color-white)' }}
            >
              sign in
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmUser;
