/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
'use client';
import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import sty from './styles/Auth.module.css';
import IconCloseModal from 'components/Icons/IconClose';
import ConfirmUser from './ConfirmUser';
import Link from 'next/link';

// eslint-disable-next-line
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

interface Props {
  onClose: (path: string) => void;
  signInHandler: MouseEventHandler<HTMLAnchorElement>;
}

const NewUser: FC<Props> = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [username, setUsername] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);

  const unameRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleSignUp = async (event: React.MouseEvent) => {
    event.preventDefault();
    const attributeList = [
      {
        Name: 'email',
        Value: emailRef?.current?.value ?? '',
      },
      {
        Name: 'given_name',
        Value: firstNameRef?.current?.value ?? '',
      },
      {
        Name: 'family_name',
        Value: lastNameRef?.current?.value ?? '',
      },
    ];
    if (localStorage.getItem('has_visited_ycmpr')) {
      attributeList.push({
        Name: 'custom:custom_tracking',
        Value: JSON.stringify({ has_visited_ycmpr: true }),
      });
    }
    await userPool.signUp(
      unameRef?.current?.value ?? '',
      passRef?.current?.value ?? '',
      attributeList,
      null,
      (err: Error) => {
        if (err) {
          console.error(err);
          return handleError(err);
        }
        setError(undefined);
        setUsername(unameRef?.current?.value ?? '');
        setSubmitted(true);
      }
    );
  };

  const handleError = (err: Error) => {
    setError(err?.message ?? err.toString().replace(/^\w*?Exception: /i, ''));
  };

  if (!submitted) {
    return (
      <div className={`${sty.modal} grid grid-2col has-basic-links`}>
        <div className={sty.modalContainerLeft_signUp}>
          <div className={sty.modalOverlay}>
            <div className={sty.modalInnerWrapper}>
              <img
                className={sty.modalOverlayImageOne}
                src={'/img/column-of-colored-triangles3.svg'}
                alt="" /* decorative */
              />
              <div className={`${sty.modalOverlayText} hdg-1`}>
                <div>Create a Free MPR </div>
                <div>
                  news profile <div className={sty.modalForYou}>for you</div>
                </div>
              </div>
              <div className={sty.modalOverlayImageTwoOuter}>
                <img
                  className={sty.modalOverlayImageTwo}
                  src={'/img/column-of-colored-triangles4.svg'}
                  alt="" /* decorative */
                />
              </div>
            </div>
          </div>
        </div>
        <div className={sty.modalContainerRight}>
          <div className={sty.topContainer}>
            <div className="hdg-2">Sign up</div>
            <button
              onClick={() => props.onClose('new-user')}
              className={sty.closeButton}
              aria-label="Close"
            >
              <IconCloseModal color="var(--color-mpr-charcoal)" />
            </button>
          </div>
          <form>
            <label
              htmlFor="firstname"
              className={`${sty.lbl} ${sty.block} type-sm`}
            >
              First Name{' '}
            </label>
            <input
              ref={firstNameRef}
              type="text"
              name="firstname"
              className={`${sty.inpt} ${sty.largeInput}`}
            />
            <label
              htmlFor="lastname"
              className={`${sty.lbl} ${sty.block} type-sm`}
            >
              Last Name{' '}
            </label>
            <input
              ref={lastNameRef}
              type="text"
              name="lastname"
              className={`${sty.inpt} ${sty.largeInput}`}
            />
            <label
              htmlFor="email"
              className={`${sty.lbl} ${sty.block} type-sm`}
            >
              Email Address{' '}
            </label>
            <input
              ref={emailRef}
              type="email"
              name="email"
              className={`${sty.inpt} ${sty.largeInput}`}
            />
            <label
              htmlFor="username"
              className={`${sty.lbl} ${sty.block} type-sm`}
            >
              Create User Name{' '}
            </label>
            <input
              ref={unameRef}
              type="text"
              name="username"
              className={`${sty.inpt} ${sty.largeInput}`}
            />

            <label
              htmlFor="password"
              className={`${sty.lbl} ${sty.block} type-sm`}
            >
              Create Password{' '}
            </label>
            <input
              className={`${sty.inpt} ${sty.largeInput}`}
              ref={passRef}
              type="password"
              name="password"
            />
            <div className={sty.supplementalText}>
              <span>*</span>
              Password must be at least 8 characters and contain one uppercase
              letter, one lowercase letter, one number and one symbol.
            </div>
            {error && <div className={sty.error}>{error}</div>}
            <div className={`${sty.container} ${sty.containerInner}`}>
              <button
                className="btn btn-primary btn-full-width btn-slim"
                onClick={handleSignUp}
              >
                Create Account
              </button>
            </div>
          </form>
          <div className={`${sty.supplementalTextBottom} type-sm`}>
            <b>Already have an account?</b>
            <div>
              <a onClick={props.signInHandler}>Sign in</a>
            </div>
          </div>
          <div className={`${sty.supplementalText}`}>
            By submitting, you consent that you are at least 18 years of age and
            to receive information about MPR&apos;s or APMG entities&apos;
            programs and offerings. The personally identifying information you
            provide will not be sold, shared, or used for purposes other than to
            communicate with you about MPR, APMG entities, and its sponsors. You
            may opt-out at any time clicking the unsubscribe link at the bottom
            of any email communication. View our{' '}
            <Link href="https://www.americanpublicmedia.org/privacy">
              Privacy Policy
            </Link>
            .
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <ConfirmUser
        onClose={() => undefined}
        emailRef={emailRef?.current?.value}
        username={username}
        signInHandler={props.signInHandler}
      />
    );
  }
};

export default NewUser;
