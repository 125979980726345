import React from 'react';
import Head from 'next/head';
import { globals } from '../../config/globals';
import Script from 'next/script';
import { useRouter } from 'next/router';

const DefaultHead = () => {
  const defaultTitle = globals.siteName;
  const snippet = `var googletag = googletag || {}; googletag.cmd = googletag.cmd || [];`;
  const router = useRouter();

  const altWeatherDescription = `Stay updated on Minnesota's weather conditions, and get the latest forecasts and alerts. Click here for your local weather report from MPR News.`;
  const altTalkingSenseDescription = `Learn how to navigate tough political conversations better. MPR News' Talking Sense will help improve your communication skills with expert advice you can trust.`;

  let description = router.asPath.includes('weather')
    ? altWeatherDescription
    : router.asPath.includes('talking-sense')
    ? altTalkingSenseDescription
    : globals.siteDescription;

  return (
    <>
      <Script
        src="https://aston-mpr-web-push-notification.s3-us-west-2.amazonaws.com/web-push-notification.js"
        strategy="lazyOnload"
      />
      <Head>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: snippet }}
          key="Googletag"
        />
        <meta
          httpEquiv="X-UA-Compatible"
          content="IE=edge,chrome=1"
          key="httpEquiv"
        />
        <title>{defaultTitle}</title>
        <link
          rel="alternate"
          type="application/rss+xml"
          href="https://www.mprnews.org/feed/homepage"
        />
        <meta name="mpr-site" content="news" key="mpr-site" />
        <meta name="title" content={defaultTitle} key="title" />
        <meta name="description" content={description} key="description" />
        {/* Twitter */}
        <meta name="twitter:site" content="@MPRNews" key="twitter:site" />
        <meta
          name="twitter:widgets:csp"
          content="on"
          key="twitter:widgets:csp"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta name="twitter:title" content={defaultTitle} key="twitter:title" />
        <meta
          name="twitter:image"
          content="https://www.mprnews.org/mpr-news-800x418.png"
          key="twitter:image"
        />
        {/* Opengraph */}
        <meta
          property="og:site_name"
          content={globals.siteName}
          key="og:site_name"
        />
        <meta property="og:title" content={defaultTitle} key="og:title" />
        <meta
          property="og:image"
          content="https://www.mprnews.org/mpr-news-940x788.png"
          key="og:image"
        />
        <meta
          property="og:description"
          content={globals.siteDescription}
          key="og:description"
        />
        <meta property="og:type" content="website" key="og:type" />
        {/* Favicons */}
        <link rel="icon" type="image/png" href="/favicon.png" key="icon" />
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/favicon.ico"
          key="shortcut icon"
        />
        <link
          rel="apple-touch-icon"
          href="/apple-touch-icon.png"
          key="apple-touch-icon"
        />
        {/* Connect to google domain font files */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;1,400;1,600&display=swap"
          rel="stylesheet"
        />
        {/* Global styles that currently can't be added to Next.js global styles */}
        {/* See styles.css for details */}
        <link href="/css/core/grids.css" rel="stylesheet" />
      </Head>
    </>
  );
};

export default DefaultHead;
